// Note, we import functions first because of https://github.com/twbs/bootstrap/issues/23331
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';

// Make background Github like
$body-bg: #ffffff;

// Remove ugly rounded corners
$enable-rounded: false;

$primary-business-color: #edc662; //#e17575; //#8ca3b5; // #286fff;
$primary-business-color-hover: #edc662; //#ef3636; //#72889a; // #04c;
// schick: #e17575
$secondary-business-color: #f07373; // #f63
$secondary-business-color-hover: #e9867d;

$light-grey-text-business-color: #a6acaf;
$grey-text-business-color: #778082;
$dark-grey-text-business-color: #515658;
$black-text-business-color: #2e2f35; //#404448;

$light-blue-background-color: rgba(105, 138, 166, .13);
$light-grey-background-color: #f8f9f9;
$dark-grey-background-color: #f2f3f4;