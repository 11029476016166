@import 'variables';

// Disable gradients - we don't need them

// Only import the required bootstrap parts
;

// import tailwindcss

// -------------------------------------------

body {
  font-family: 'Jost', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
  margin: 0;
}

#root {
  overflow-x: hidden;
}

a, svg  {
  cursor: pointer;
}
input, select, textarea, button {
  font-family:inherit;
  font-size: 1rem;
}

ul {
  list-style-type: none; 
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #edc662 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-list {
  font-size: 1rem;
}

input {
  padding: 0;
}

input:focus {
	outline: none;
  	border: initial;
}

.MuiAlert-root {
  text-align: left;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: #212121;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  margin-bottom: 0;
}

@media (min-width: 300px) {
  .container {
    max-width: 300px;
  }
}

@media (min-width: 460px) {
  .container {
    max-width: 420px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 620px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 728px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1004px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1260px;
  }
}

::selection {
  color: #ffffff;
  background: $primary-business-color-hover;
}

.is-lead {
  font-size: 1.25rem;
  //font-weight: $font-weight-light;
}

a:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

// .headline-marker-1:before {
//   content: "";
//   position: absolute;
//   //top: 23px;
//   z-index: -1;
//   //left: -52px;
//   background: url("/svg/headlinemarker1.svg") no-repeat;
//   background-size: contain;
//   margin-top: 10px;
//   width: 300px;
//   height: 132px;
// }

@media (max-width: 768px) {
  .headline-marker-1:before {
    width: 250px;
  }
}

// .headline-marker-small:before {
//   content: "";
//   position: absolute;
//   //top: 23px;
//   z-index: -1;
//   //left: -52px;
//   background: url("/svg/headlinemarker1.gray.svg") no-repeat;
//   background-size: contain;
//   width: 50%;
//   height: 132px;
// }

// .background-pass:after {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   background: url("/img/headergfx.svg") no-repeat center;
//   //background-size: contain;
//   width: 100%;
//   height: 45%;
//   transform: rotateX(180deg) scale(1.3);
//   margin-top: -260px;
// }

h1 {
  font-weight: 700;
}

.btn {
  padding: 0.75rem 2.5rem;
  border-radius: 9999px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.025em;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  cursor: pointer;
}

.btn-xs {
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.025em;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: .875rem;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-primary {
  --bg-opacity: 1;
  background-color: $primary-business-color;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

li 
{
    z-index:10;
}
::placeholder {
  color: #bdbdbd;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-color-primary {
  background-color: #edc662;
}

.MuiDrawer-paperAnchorBottom {
  clip-path: ellipse(76% 73% at 50% 78%);
}

.MuiDrawer-paperAnchorTop {
  clip-path: ellipse(91% 62% at 50% 36%);
}

.react-datepicker__input-container input {
  border-radius: 25px;
}

.Toastify__toast--success {
  background: #ebebf4 !important;
}


select {
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}

// .carousel {
//   overflow: hidden;
// }

.slider-container {
  width: 100%;
  height: 250px;
  
  /* Add this */
  position:fixed;
  top:0;
  left:0;
  
  }

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

// .carousel-item {
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   height: 200px;
//   background-color: green;
//   color: #fff;
// }

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}


.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}

.StyledStepper-root {
  padding: 0;
}

.MuiDrawer-paperAnchorBottom {
  clip-path: ellipse(95% 54% at 49% 55%);
  z-index: 1400;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiBackdrop-root {
  background: rgb(10, 25, 41)
}

.react-horizontal-scrolling-menu--item:first-child {
  margin-left: 30px;
}

.carousel .control-next.control-arrow:before {
  content: '';
  border: solid #212121;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: '';
  border: solid #212121;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@media only screen and (max-width: 600px) {
  .carousel .control-next.control-arrow:before {
    border-width: 0 4px 4px 0;
  }
}

@media only screen and (max-width: 600px) {
  .carousel .control-prev.control-arrow:before {
    border-width: 0 4px 4px 0;
  }
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
  background-color: transparent;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
  background-color: transparent;
}

.MuiAlert-message {
  font-family: 'Jost', sans-serif;
  font-weight: normal;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;  /* Hide scrollbar on Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar on Firefox */
}

div.scrollmenu button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.center {
  text-align: center; /* Centers inline content */
  display: block; /* Ensures block-level elements behave correctly */
}